<template>
  <div id="StudentGrow">
    <div class="info">
      <img src="@/assets/images/scene/banner_s_g.png" alt="" />
      <div>
        <h2>应用场景 <span>「</span> 学生个性成长 <span>」</span></h2>
        <!-- <p>Application Scenario-Personalized Growth Of Students</p> -->
      </div>
    </div>
    <h2>应用场景</h2>
    <!-- <p>Application Scenario</p> -->
    <div class="a_s">
      <div class="a_s_box">
        <img src="@/assets/images/scene/a_s.png" alt="" />
        <div class="content">
          学校需要对学生进行全方位的成长评控体系建设的时候可运用此系统。其中主要聚焦在过程性评价以及课程育人、活动育人、空间育人、协同育人等多种育人模式。
        </div>
      </div>
      <div class="a_s_bg"></div>
    </div>
    <h2>案例</h2>
    <!-- <p>Case Study</p> -->
    <div class="c_s">
      <h2>深圳市南山区香山里小学</h2>
      <p>
        基于香山里特色的学生成长体系，建立三级等级指标。围绕学校特色评价体系，基于日常成长环境选择实施评价指标，通过web、手机APP的评价面板对学生进行日常表现评价。
      </p>
      <ul class="c_s_img">
        <li><img src="@/assets/images/scene/s_g_item_01.png" alt="" /></li>
        <li><img src="@/assets/images/scene/s_g_item_02.png" alt="" /></li>
        <li><img src="@/assets/images/scene/s_g_item_03.png" alt="" /></li>
      </ul>
      <div class="c_s_item">
        <div class="item">
          <span><i class="icon-a-Lightbulb-line iconfont"></i></span>
          <div>
            借助区块链技术，为每个指标生成唯一可追溯的“成长币”。通过成长币，线上线下相结合的方式，围绕课堂、日常、等多场景帮助学校开展学生日常过程性评价。基于学生成长体系中的课堂评价指标，提供多种方式支持学校、教师开展对班级、小组、学生的课堂表现进行登记评价。
          </div>
        </div>
        <div class="item">
          <span><i class="icon-Trophy-line iconfont"></i></span>
          <div>
            学生成长活动可支持科技节、日常阅读、阳光体育等打卡活动，同时还支持期末学校游园活动，通过班级量化主题活动可以对行政班、课程班进行日常量化登记评比，并优化了量化活动的评价量表，以及荣誉勋章解锁。
          </div>
        </div>
        <div class="item">
          <span><i class="icon-head-star-line iconfont"></i></span>
          <div>
            通过人脸识别系统对学生进行出勤情况登记并上报，支持学生、家长在线请假班主任进行审批，支持老师为学生登记请假，支持校医、班主任。任课教师、年级组长、德育教学主任、校长副校长等查看学生每日出勤数据。统一设定作业登记评价指标基于此来对学生开展日常作业完成登记，并记录到学生成长中心，并形成数据积累与分析，教务主任、年级主任、班主任等可查看相关统计分析结果。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudentGrow',
  data() {
    return {}
  },
  mounted() {
    this.$store.commit('setPcHeaderIsTransparent', 'transparent')
  },
}
</script>

<style lang="scss" scoped>
%StudentGrow {
  h1,
  h2,
  h3,
  h4 {
    color: #333;
  }
  width: 100%;
  position: relative;
  background: #f6f5f5;
  @include px2vw('padding-bottom', 140);
  // @include px2vw('height', 1255);
  .info {
    position: relative;
    img {
      width: 100%;
    }
    div {
      text-align: center;
      @include px2vw('width', 880);
      @include px2vw('padding', 18);
      @include px2vw('padding-bottom', 60);
      background: rgba(0, 0, 0, 0.3);
      color: $--color-white;
      text-shadow: 0px 2px 12px rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 37%;
      left: 26%;
      h2 {
        @include px2vw('font-size', 56);
        font-weight: normal;
        margin: 32px 0 0;
        // margin-top: 32px;
        color: $--color-white;
        letter-spacing: 4px;
        span {
          @include px2vw('font-size', 32);
          vertical-align: top;
          font-weight: bold;
        }
        span:nth-child(2) {
          vertical-align: bottom;
        }
      }
      p {
        margin: 0;
        @include px2vw('font-size', 33);
        @include px2vw('margin-top', 25);
        letter-spacing: 1px;
      }
    }
  }
  > h2 {
    font-size: 36px;
    text-align: center;
    @include px2vw('margin-top', 125);
  }
  > p {
    text-align: center;
    font-size: 24px;
    color: #333333;
    position: relative;
    &::after {
      content: ' ';
      width: 66px;
      height: 3px;
      display: inline-block;
      position: absolute;
      left: 48%;
      margin-top: 45px;
      background: $--color-primary;
    }
  }
  .a_s {
    width: 90%;
    // height: 400px;
    margin: 60px auto;
    background: url('~@/assets/images/scene/social_a_s1.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    max-width: 1320px;
    margin: 50px auto;

    padding: 50px 0;
    .a_s_box {
      background: #f2f2f2;
      margin: 0 60px;
      // padding: 70px 50px 50px 50px;
      z-index: 2;
      img {
        // @include px2vw('width', 440);
        width: 35%;
        vertical-align: middle;
      }
      span {
        margin-left: 5%;
        display: inline-block;
        width: 60%;
        line-height: 32px;
        font-size: 18px;
        text-align: justify;
        color: #666;
        vertical-align: middle;
      }
      .content {
        margin-left: 5%;
        display: inline-block;
        width: 60%;
        line-height: 32px;
        font-size: 18px;
        text-align: justify;
        color: #666;
        vertical-align: middle;
      }
    }
    .a_s_bg {
      // position: absolute;
      // background: $--color-whiteaf8;
      width: 475px;
      // height: 596px;
      // left: 0;
      // top: -108px;
      border-radius: 0% 56% 34% 0%;
      z-index: -1;
    }
  }
  .c_s {
    width: 90%;
    max-width: 1320px;
    margin: 0 auto;
    h2 {
      text-align: center;
      font-size: 40px;
      font-weight: normal;
      margin-top: 50px;
      margin-bottom: 50px;
    }
    p {
      line-height: 32px;
      font-size: 18px;
      text-align: justify;
      color: #666;
    }
    .c_s_img {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
      margin-bottom: 0;
      @include px2vw('margin-top', 50);

      li {
        width: 30%;
        img {
          width: 100%;
        }
      }
    }
    .c_s_item {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > div {
        box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.08);
        padding: 32px 62px 32px 109px;
        line-height: 32px;
        font-size: 18px;
        text-align: justify;
        color: #666;
        letter-spacing: 1px;
        margin-top: 50px;
        box-sizing: border-box;
      }
      .item:nth-child(1) {
        margin-left: 60px;
        margin-right: 40px;
        width: calc((100% - 160px) / 2);
      }
      .item:nth-child(2) {
        margin-left: 60px;
        width: calc((100% - 160px) / 2);
      }
      .item:nth-child(3) {
        margin-left: 60px;
        width: calc(100% - 60px);
      }
      .item {
        position: relative;
        min-height: 115px;
        span {
          position: absolute;
          width: 72px;
          height: 130px;
          top: 9px;
          left: -34px;
          background-color: $--color-primary;
          display: inline-block;
          transform: rotate(30deg);
          border-radius: 3px;
          box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.08);
          i {
            color: $--color-white;
            font-size: 75px;
            transform: rotate(-30deg);
            position: absolute;
            top: 48px;
            left: 0px;
          }
        }
        span:before {
          content: '';
          display: block;
          position: absolute;
          width: 0;
          height: 0;
          right: 71px;
          border-width: 65px 40px;
          border-style: solid;
          border-color: transparent $--color-primary transparent transparent;
          border-radius: 3px;
        }
        span:after {
          content: '';
          display: block;
          position: absolute;
          width: 0;
          height: 0;
          left: 71px;
          border-width: 65px 40px;
          border-style: solid;
          border-color: transparent transparent transparent $--color-primary;
          border-radius: 3px;
          top: 0;
        }
      }
    }
  }
}
.view-pc > #StudentGrow {
  @extend %StudentGrow;
  padding-bottom: 100px;
  > h2 {
    font-size: 36px;
    margin-top: 50px;
  }
  .a_s {
    margin-top: 50px;
  }
  @media screen and (max-width: 1366px) {
    .c_s_item {
      .item {
        width: 100% !important;
      }
      .item:nth-child(2) {
        width: 100% !important;
      }
    }
  }
  @media screen and (max-width: 1100px) {
    .a_s {
      margin-top: 50px;
      width: calc(90% - 120px);
      .a_s_box {
        width: 100%;
        display: flex;
        img {
          width: 35%;
        }
        span {
          width: 100%;
          margin-top: 20px;
          margin-left: 5%;
        }
      }
    }
    .c_s {
      width: 90%;
      h2 {
        margin-top: 50px;
      }
      .c_s_img {
        li {
          width: 100%;
          margin: 10px auto 0;
          // margin-top: 10px;
        }
      }
    }
  }
  @media (min-width: 570px) and(max-width:1440px) {
    .c_s {
      h2 {
        margin-top: 40px;
        margin-bottom: 20px;
      }
    }
  }
}

.view-mobile > #StudentGrow {
  @extend %StudentGrow;
  // @include px2vw('height', 3000);
  padding-bottom: 30px;
  > h2 {
    font-size: 22px !important;
  }
  .info {
    margin-top: 44px;
    height: 153px;
    overflow: hidden;
    display: flex;
    div {
      width: 100vw;
      height: 75%;
      padding: 10% 0 0;
      // padding-top: 10%;
      top: 0;
      left: 0;
      h2 {
        font-size: 22px;
        margin-top: 15px;
      }
      p {
        font-size: 10px;
      }
    }
  }
  > p {
    font-size: 14px;

    &::after {
      left: 40% !important;
      margin-top: 35px;
    }
  }
  .student-grow-box {
    margin-top: 40px;
  }
  .a_s {
    background: #f2f2f2;
    padding: 0 20px;
    width: calc(90% - 40px);
    height: 100%;
    margin: 30px 5% 0;
    // margin-bottom: 0;
    flex-wrap: wrap;
    .a_s_box {
      // width: 90%;
      padding: 30px 20px;
      margin: 0 !important;
      display: flex;
      flex-wrap: wrap;
      img {
        width: 100%;
      }
      .content {
        font-size: 14px;
        line-height: 28px;
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
      }
    }
    .a_s_bg {
      display: none;
    }
  }
  .c_s {
    width: 90%;
    h2 {
      margin-top: 30px;
      font-size: 24px;
      margin-bottom: 30px;
    }
    p {
      font-size: 14px;
      line-height: 28px;
      text-align: justify;
      margin-bottom: 30px;
    }
    .c_s_img {
      // margin-bottom: 20px;
      li {
        width: 100%;
        margin: 0 auto 30px;
        // margin-top: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .c_s_item {
      width: 90%;
      margin: 0 auto;
      > div {
        padding: 12px 20px 22px 50px;
        line-height: 28px;
        font-size: 14px;
        text-align: justify;
        color: #666;
      }
      .item {
        width: 100% !important;
        margin-top: 30px;
        padding: 30px 20px 30px 50px;
        // padding: 30px ;
        span {
          width: 41px;
          height: 68px;
          top: 9px;
          left: -17px;
          transform: rotate(30deg);
          i {
            font-size: 40px;
            transform: rotate(-30deg);
            top: 23px;
          }
        }
        span:before {
          right: 40px;
          border-width: 34px 20px;
        }
        span:after {
          left: 40px;
          border-width: 34px 20px;
        }
      }
      .item:nth-child(1) {
        margin-left: 0;
        margin-right: 0;
      }
      .item:nth-child(2) {
        margin-left: 0;
        margin-right: 0;
      }
      .item:nth-child(3) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  @media screen and (max-width: 768px) {
    > p {
      &::after {
        left: 45%;
        margin-top: 35px;
      }
    }
  }
}
</style>
